import React, { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  className?: string;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span';
};

const SectionTag = ({ children, className, tag: Tag = 'div' }: Props) => {
  return (
    <Tag
      className={`font-secondary w-fit rounded-3xl border px-5 py-2 text-xs uppercase lg:text-sm ${
        className
          ? className
          : 'border-secondary bg-secondaryLight text-secondary'
      }`}
    >
      {children}
    </Tag>
  );
};

export default SectionTag;
